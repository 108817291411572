import React from 'react';

const LegalPrivacyPolicy = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.5656 42.5151H17.2143C15.4273 42.5151 13.7132 41.8051 12.4497 40.5416C11.1861 39.278 10.4766 37.5643 10.4766 35.7774V24.7612C10.4766 22.9742 11.1861 21.2605 12.4497 19.997C13.7132 18.7334 15.4273 18.0234 17.2143 18.0234H31.5656C33.3526 18.0234 35.0658 18.7334 36.3294 19.997C37.593 21.2605 38.3034 22.9742 38.3034 24.7612V35.7437C38.3078 36.6313 38.1366 37.511 37.8 38.3324C37.4634 39.1537 36.9678 39.9004 36.3417 40.5296C35.7157 41.1589 34.9712 41.6583 34.1515 41.999C33.3318 42.3397 32.4533 42.5151 31.5656 42.5151ZM17.2143 19.7416C15.8741 19.7416 14.5883 20.274 13.6406 21.2216C12.6929 22.1693 12.161 23.4547 12.161 24.7949V35.7774C12.161 37.1176 12.6929 38.4029 13.6406 39.3506C14.5883 40.2983 15.8741 40.8307 17.2143 40.8307H31.5656C32.9059 40.8307 34.1908 40.2983 35.1385 39.3506C36.0861 38.4029 36.6189 37.1176 36.6189 35.7774V24.7612C36.6189 23.4209 36.0861 22.1356 35.1385 21.1879C34.1908 20.2402 32.9059 19.7079 31.5656 19.7079L17.2143 19.7416Z"
      fill="#475467"
    />
    <path
      d="M33.3511 19.7448C33.1277 19.7448 32.9136 19.656 32.7556 19.498C32.5977 19.3401 32.5089 19.1259 32.5089 18.9026V15.8031C32.5089 13.6498 31.6537 11.5848 30.1311 10.0622C28.6085 8.53961 26.5435 7.68443 24.3902 7.68443C22.237 7.68443 20.172 8.53961 18.6494 10.0622C17.1268 11.5848 16.2716 13.6498 16.2716 15.8031V18.9026C16.2716 19.1259 16.1828 19.3401 16.0248 19.498C15.8669 19.656 15.6527 19.7448 15.4294 19.7448C15.206 19.7448 14.9918 19.656 14.8339 19.498C14.676 19.3401 14.5872 19.1259 14.5872 18.9026V15.8031C14.5872 13.2031 15.6199 10.7097 17.4584 8.87126C19.2969 7.03277 21.7902 6 24.3902 6C26.9903 6 29.4836 7.03277 31.3221 8.87126C33.1605 10.7097 34.1933 13.2031 34.1933 15.8031V18.9026C34.1933 19.1259 34.1053 19.3401 33.9474 19.498C33.7894 19.656 33.5745 19.7448 33.3511 19.7448Z"
      fill="#475467"
    />
    <path
      d="M24.3896 36.1493C23.7642 36.1493 23.1647 35.9007 22.7224 35.4585C22.2802 35.0162 22.0316 34.4167 22.0316 33.7913V32.2751C21.4453 31.8808 20.9638 31.3494 20.6292 30.7272C20.2946 30.1049 20.1168 29.4108 20.1111 28.7043C20.1111 28.1424 20.2218 27.5858 20.4368 27.0667C20.6518 26.5477 20.9672 26.0761 21.3645 25.6788C21.7618 25.2815 22.233 24.9665 22.7521 24.7515C23.2711 24.5365 23.8277 24.4258 24.3896 24.4258C24.9515 24.4258 25.5081 24.5365 26.0271 24.7515C26.5462 24.9665 27.0174 25.2815 27.4147 25.6788C27.812 26.0761 28.1274 26.5477 28.3424 27.0667C28.5574 27.5858 28.6681 28.1424 28.6681 28.7043C28.6624 29.4108 28.4846 30.1049 28.15 30.7272C27.8154 31.3494 27.3339 31.8808 26.7476 32.2751V33.7913C26.7476 34.4167 26.499 35.0162 26.0568 35.4585C25.6145 35.9007 25.015 36.1493 24.3896 36.1493ZM24.3896 26.0428C23.7016 26.0428 23.042 26.3158 22.5555 26.8023C22.069 27.2888 21.7955 27.9489 21.7955 28.6369C21.7999 29.12 21.9372 29.5925 22.1928 30.0026C22.4483 30.4127 22.812 30.7444 23.2439 30.9612C23.3856 31.0261 23.5057 31.1307 23.5893 31.2622C23.673 31.3937 23.7172 31.5468 23.716 31.7026V33.7239C23.716 33.9026 23.787 34.0737 23.9134 34.2001C24.0397 34.3264 24.2109 34.3975 24.3896 34.3975C24.5683 34.3975 24.7395 34.3264 24.8658 34.2001C24.9922 34.0737 25.0632 33.9026 25.0632 33.7239V31.7026C25.062 31.5468 25.1062 31.3937 25.1899 31.2622C25.2735 31.1307 25.3936 31.0261 25.5353 30.9612C25.9672 30.7444 26.3309 30.4127 26.5864 30.0026C26.842 29.5925 26.9793 29.12 26.9837 28.6369C26.9661 27.9606 26.6853 27.3179 26.2007 26.8459C25.7161 26.3739 25.0661 26.11 24.3896 26.1102V26.0428Z"
      fill="#475467"
    />
  </svg>
);

export default LegalPrivacyPolicy;
